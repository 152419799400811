import { useUserStoreWithOut } from '@/store/modules/user'

export default {
  mounted(el, binding) {
    const user = useUserStoreWithOut()
    const { backend_per, money_per, phone_per, refund_per } = user?.userInfo?.admin
    function hide(key) {
      const obj = { backend_per, money_per, phone_per, refund_per }
      return obj[key] === 0
    }
    const { value } = binding
    if (hide(value)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}
